/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "url-search-params-polyfill";
import {
  sendPageviewToAnalytics,
  setGaCookie,
} from "./src/utils/analyticsHelpers";

// Send data to analytics when the route changes:
export const onRouteUpdate = ({ location }) => {
  sendPageviewToAnalytics({ path: location.pathname });
};

// Set the google analytics cookie when first entering the app:
export const onClientEntry = () => {
  setGaCookie();
};
