import axios from "axios";
import Cookies from "js-cookie";

// If it is available, use the ga function to create the google analytics cookie.
export const setGaCookie = () => {
  /*global ga*/
  if (typeof ga === "function") {
    if (!Cookies.get("_ga")) {
      // We add the correct google analytics tid based on the circle ci branch
      // to the window object. Check gatsby-ssr.js to see how.
      ga("create", window.GA_TID ?? "", "auto");
    }
  }
};

// Get the google analytics Cid from the cookie that google analytics sets:
const getAnalyticsCid = () => {
  if (typeof ga === "function" && Cookies.get("_ga")) {
    return Cookies.get("_ga")
      .split(".")
      .slice(-2)
      .join(".");
  }
};

export const sendPageviewToAnalytics = payload => {
  // Call our proxied path to send data to analytics.
  const sendData = () => {
    // Get the client id from the cookies generated by google analytics:
    const cid = getAnalyticsCid();
    // If we don't have a cid, no sense sending information to analytics:
    if (!cid) {
      return;
    }
    const data = {
      v: 1,
      t: "pageview",
      // We add the correct google analytics tid based on the circle ci branch
      // to the window object. Check gatsby-ssr.js to see how.
      tid: window.GA_TID ?? "",
      cid,
      dp: payload.path,
      dt: document.title,
      // This is our custom dimension value.
      cd5: "FA-wifilogin",
      dh: "wifilogin.finavia.fi",
    };
    sendDataToProxiedAnalytics(data).catch(function(response) {
      console.log(response);
    });
  };

  // We need to wrap this in a timeout to make sure that the document title
  // has been updated by react-helmet. This solution comes from here:
  // https://github.com/gatsbyjs/gatsby/commit/42f509eadb06753f7b529f3682f22e012c21dc9b
  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendData);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendData, 32);
  }
};

export const sendLoginEventToAnalytics = payload => {
  // Call our proxied path to send data to analytics.
  // Get the client id from the cookies generated by google analytics:
  const cid = getAnalyticsCid();
  // If we don't have a cid, no sense sending information to analytics:
  if (!cid) {
    return;
  }
  const data = {
    v: 1,
    t: "event",
    // We add the correct google analytics tid based on the circle ci branch
    // to the window object. Check gatsby-ssr.js to see how.
    tid: window.GA_TID ?? "",
    cid,
    dp: payload.path,
    dt: document.title,
    // This is our custom dimension value.
    cd5: "FA-wifilogin",
    dh: "wifilogin.finavia.fi",
    el: "Ok",
    ev: 0,
    ea: "Wifi",
    ec: "Login",
    cm1: 1,
  };
  return sendDataToProxiedAnalytics(data);
};

const sendDataToProxiedAnalytics = data => {
  // Data need to be encoded to be sent as form data.
  const encodedData = new URLSearchParams(data).toString();
  return axios({
    method: "post",
    url: "/gaproxy/collect",
    data: encodedData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
